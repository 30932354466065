import { skipToken } from '@reduxjs/toolkit/query'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { LoadingSpinner } from 'mgr/layout/StyledComponentUtils'
import { VmsIcons } from 'svr/common/VmsIcons'
import { GuestFacingUpgradeAdapter, useGetUpgradesQuery } from '@sevenrooms/core/api'
import type { GuestFacingCategoryUpgrade } from '@sevenrooms/core/domain'
import { useForm, useWatchMany } from '@sevenrooms/core/form'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { Upgrades, upgradesMessages, useUpgradesForm, type UpgradesForm, useDefaultValues } from '@sevenrooms/mgr-reservation-slideout'
import { toggleStep, clearScrollIntoView } from '../../actions/BookActions'
import { changeUpgradesForm } from '../../actions/PaymentActions'
import { defaultUpgrades } from '../../components/availability/defaults'
import BookStepComponent from '../../components/BookStepComponent'
import { useStoreSelector } from '../../selectors/useStoreSelector'
import { upgradesStepLocales } from './UpgradesStep.locales'
import type { ValidateFieldMaps } from '../BookReservation.types'

interface UpgradesStepProps {
  validateFieldMaps: ValidateFieldMaps
}

export function UpgradesStep(props: UpgradesStepProps) {
  const { internalUpsellsEnabled } = useStoreSelector(state => state.bookPaymentState)
  return internalUpsellsEnabled ? <UpgradesStepCmp {...props} /> : null
}

function UpgradesStepCmp({ validateFieldMaps }: UpgradesStepProps) {
  const { formatMessage } = useLocales()
  const dispatch = useDispatch()

  const { scrollIntoViewStep, isStepCollapsed, selectedVenue, isEditMode } = useStoreSelector(state => state.bookState)
  const { partySize, selectedTimeSlot, actual } = useStoreSelector(state => state.bookAvailabilityState)
  const { currencyCode } = useStoreSelector(state => state.bookPaymentState)

  const { data = defaultUpgrades, isFetching: isFetchingUpgrades } = useGetUpgradesQuery(selectedVenue?.id ?? skipToken)

  const categories = useMemo(
    () => GuestFacingUpgradeAdapter.upgradeToCategory(data, selectedTimeSlot?.upsell_categories ?? []),
    [data, selectedTimeSlot]
  )
  const categoriesPreselected = useMemo(() => {
    const categoryFilter = Object.keys(actual?.upsell_snapshot?.selected_categories ?? {}).filter(
      id => !categories.find(cat => cat.id === id)
    )
    const inventoryFilter = Object.keys(actual?.upsell_snapshot?.selected_inventories ?? {})
    return GuestFacingUpgradeAdapter.upgradeToCategory(data, categoryFilter, inventoryFilter)
  }, [actual, data, categories])

  const defaultValues = useDefaultValues({
    partySize,
    categories,
    categoriesPreselected,
    inventoryPreselected: actual?.upsell_snapshot?.selected_inventories,
    override: actual?.booked_with_required_upgrade_override,
  })
  const upgradesSchema = useUpgradesForm()
  const {
    field,
    trigger,
    reset,
    formState: { isDirty },
  } = useForm(upgradesSchema, { defaultValues })

  const [overrideValue, categoriesValue, categoriesPreselectedValue] = useWatchMany(field, [
    'override',
    'categories',
    'categoriesPreselected',
  ])

  const { totalPrice, selectedUpgrades } = useMemo(
    () => getSelectedUpgradesTotal(categories, categoriesValue),
    [categories, categoriesValue]
  )

  useEffect(() => {
    reset(defaultValues, { keepDirty: true, keepDirtyValues: true })
    // eslint-disable-next-line
  }, [defaultValues])

  // cleanup
  useEffect(() => () => reset(defaultValues), [defaultValues, reset])

  // integration into legacy redux
  useEffect(() => {
    dispatch(changeUpgradesForm({ ..._.cloneDeep(categoriesValue), ..._.cloneDeep(categoriesPreselectedValue) }, isDirty, overrideValue))
  }, [categoriesValue, categoriesPreselectedValue, isDirty, overrideValue, dispatch])

  // integration into legacy custom validation mechanism
  // eslint-disable-next-line no-param-reassign
  validateFieldMaps.upgrades.categories = {
    isValid: () =>
      trigger('categories', { shouldFocus: true }).then(valid =>
        !valid && !overrideValue ? formatMessage(upgradesMessages.errorRequiredUpgrades) : true
      ),
  }

  const hasRequiredCategories = useMemo(
    () =>
      categories.some(category => {
        const isMinRequired = category.category.minQuantityType !== 'NONE_REQUIRED'
        const isMaxRequired = category.category.maxQuantityType !== 'UNLIMITED'
        return isMinRequired || isMaxRequired
      }),
    [categories]
  )

  useEffect(() => {
    if (categories.length > 0 && isStepCollapsed.upgrades) {
      dispatch(toggleStep('upgrades', !isEditMode))
    }
  }, [categories.length, dispatch, isEditMode, isStepCollapsed.upgrades])

  const subTitle =
    isStepCollapsed.upgrades &&
    totalPrice > 0 &&
    `${selectedUpgrades.join(', ')}\u00A0 · \u00A0${FormatService.formatCurrency(totalPrice, currencyCode)}`

  if (isFetchingUpgrades) {
    return <LoadingSpinner />
  }
  if (categories.length === 0 && categoriesPreselected.length === 0) {
    return null
  }
  return (
    <BookStepComponent
      title={formatMessage(upgradesStepLocales.title)}
      subTitle={subTitle}
      onToggle={() => dispatch(toggleStep('upgrades'))}
      onCompleteScrollIntoView={() => dispatch(clearScrollIntoView())}
      isCollapsed={isStepCollapsed.upgrades}
      isCollapsible={!hasRequiredCategories}
      scrollIntoView={scrollIntoViewStep === 'upgrades'}
      testId="sr-section-upgrades"
      icon={VmsIcons.UpgradeFill}
      iconLine={VmsIcons.UpgradeLine}
    >
      <FieldGroup>
        <Upgrades
          field={field}
          categories={categories}
          categoriesPreselected={categoriesPreselected}
          currencyCode={currencyCode}
          partySize={partySize}
          hasRequiredCategories={hasRequiredCategories}
        />
      </FieldGroup>
    </BookStepComponent>
  )
}

const FieldGroup = styled.div`
  margin: 15px 12px;
`

export const getSelectedUpgradesTotal = (categoriesData: GuestFacingCategoryUpgrade[], selectedCategories: UpgradesForm['categories']) => {
  let totalPrice = 0
  const selectedUpgrades: string[] = []
  categoriesData.forEach(({ category, upgrades }) => {
    const selectedCategory = selectedCategories[category.id]
    if (selectedCategory) {
      upgrades.forEach(({ id, price, name }) => {
        const selectedUpgradeCount = selectedCategory.upgrades[id]
        if (selectedUpgradeCount) {
          totalPrice += price * selectedUpgradeCount
          selectedUpgrades.push(`${selectedUpgradeCount}x ${name}`)
        }
      })
    }
  })
  return { totalPrice, selectedUpgrades }
}
