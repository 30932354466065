/* eslint-disable camelcase */
import { useMemo } from 'react'
import type { AutomaticUpsell } from '@sevenrooms/core/api'
import type { GuestFacingUpgrade, GuestFacingUpgradeCategory, GuestFacingUpgradeInventory } from '@sevenrooms/core/domain'
import { z } from '@sevenrooms/core/form'
import type { UpgradesForm } from '../Upgrades'

type CardEntryOption = 'manual' | 'paylink' | string | null

export type PaymentForm = z.infer<ReturnType<typeof usePaymentForm>>

export const usePaymentForm = () => {
  const charges = useChargesForm()
  const category = useCategoryForm()
  const clientSelectGratuity = useClientSelectGratuityForm()
  return useMemo(
    () =>
      z.object({
        amount: z.number().nullable(),
        charges,
        categoriesBundled: z.record(category),
        categories: z.record(category),
        clientSelectGratuity,
      }),
    [charges, category, clientSelectGratuity]
  )
}

export type CategoryForm = z.infer<ReturnType<typeof useCategoryForm>>

const useCategoryForm = () => {
  const charges = useChargesForm()
  const upgrade = useUpgradeForm()
  return useMemo(
    () =>
      z.object({
        name: z.string(),
        upgrades: z.record(upgrade),
        charges,
      }),
    [charges, upgrade]
  )
}

export type UpgradeForm = z.infer<ReturnType<typeof useUpgradeForm>>

const useUpgradeForm = () =>
  useMemo(
    () =>
      z.object({
        amount: z.number().nullable(),
        name: z.string(),
        count: z.number(),
      }),
    []
  )

export type ChargesForm = z.infer<ReturnType<typeof useChargesForm>>

const useChargesForm = () =>
  useMemo(
    () =>
      z.object({
        gratuity: z.number().nullable(),
        gratuityClientSelect: z.boolean(),
        requireGratuityCharge: z.boolean(),
        service: z.number().nullable(),
        taxId: z.string().nullable(),
        applyGratuity: z.boolean(),
        applyService: z.boolean(),
        applyTax: z.boolean(),
      }),
    []
  )

export type ClientSelectGratuityForm = z.infer<ReturnType<typeof useClientSelectGratuityForm>>

const useClientSelectGratuityForm = () =>
  useMemo(
    () =>
      z
        .object({
          gratuity: z.number().nullable(),
          gratuityClientSelect: z.boolean(),
          requireGratuityCharge: z.boolean(),
          applyGratuity: z.boolean(),
          cardEntryOption: z.string().nullable(),
        })
        .superRefine((values, ctx) => {
          if (values.cardEntryOption !== 'paylink' && values.requireGratuityCharge && (values.gratuity == null || values.gratuity === 0)) {
            ctx.addIssue({ code: z.ZodIssueCode.custom, path: ['gratuity'] })
          }
        }),
    []
  )

interface UseDefaultValuesParams {
  partySize: number
  chargeDetails: {
    chargeAmount: string
    chargeApplyTax: boolean | null
    taxGroupId: string | null
    applyServiceCharge: boolean | null
    serviceCharge: string | null
    applyGratuityCharge: boolean | null
    gratuityCharge: string | null
    paylinkGratuityType: GratuityType
    cardEntryOption?: CardEntryOption
    clientSelectGratuity: ClientSelectGratuity
  }
  bundledUpgrades?: AutomaticUpsell[]
  upgrades: GuestFacingUpgrade
  defaultGratuity: number
  defaultServiceCharge: number
  categoriesForm?: UpgradesForm['categories']
  isPreselectedTime: boolean
  oldValues?: PaymentForm
  clientSelectGratuityCharge: number | null
}

export type GratuityType = 'gratuity_percentage' | 'client_select_gratuity' | 'require_client_select_charge'

export const useDefaultValues = ({
  partySize,
  chargeDetails,
  bundledUpgrades,
  upgrades,
  defaultGratuity,
  defaultServiceCharge,
  categoriesForm,
  isPreselectedTime,
  oldValues,
  clientSelectGratuityCharge,
}: UseDefaultValuesParams): PaymentForm =>
  useMemo(() => {
    const { inventories, categories } = upgrades
    const selectedUpgradesCategories: PaymentForm['categories'] = {}
    const selectedBundledUpgradesCategories: PaymentForm['categoriesBundled'] = {}
    let clientSelectGratuity: PaymentForm['clientSelectGratuity'] = {
      gratuity: clientSelectGratuityCharge,
      gratuityClientSelect: chargeDetails.clientSelectGratuity.gratuity ? chargeDetails.clientSelectGratuity.gratuityClientSelect : false,
      requireGratuityCharge: chargeDetails.clientSelectGratuity.gratuity ? chargeDetails.clientSelectGratuity.requireGratuityCharge : false,
      applyGratuity: chargeDetails.clientSelectGratuity.gratuity ? chargeDetails.clientSelectGratuity.applyGratuity : false,
      cardEntryOption: String(chargeDetails.cardEntryOption),
    }

    // // If the charge has client select gratuity enabled, we can use the values from there
    if (!clientSelectGratuity.gratuityClientSelect && chargeDetails.paylinkGratuityType !== 'gratuity_percentage') {
      clientSelectGratuity = {
        ...clientSelectGratuity,
        gratuityClientSelect: true,
        requireGratuityCharge: chargeDetails.paylinkGratuityType === 'require_client_select_charge',
        applyGratuity: !!chargeDetails.applyGratuityCharge,
        cardEntryOption: String(chargeDetails.cardEntryOption),
      }
    }

    Object.entries(categoriesForm ?? {}).forEach(([categoryId, { upgrades: formUpgrades }]) => {
      const category = categories.find(category => category.id === categoryId)
      const upgradesFiltered = Object.entries(formUpgrades).filter(([, count]) => count > 0)
      if (category && upgradesFiltered.length) {
        selectedUpgradesCategories[category.id] = getCategoryForm(
          category,
          defaultGratuity,
          defaultServiceCharge,
          clientSelectGratuityCharge
        )

        if (!clientSelectGratuity.gratuityClientSelect && selectedUpgradesCategories[category.id]?.charges.gratuityClientSelect) {
          // If client select gratuity fields arnt set yet, check if we can set them now
          clientSelectGratuity = {
            ...clientSelectGratuity,
            gratuityClientSelect: selectedUpgradesCategories[category.id]?.charges.applyGratuity ?? false,
            requireGratuityCharge: selectedUpgradesCategories[category.id]?.charges.requireGratuityCharge ?? false,
            applyGratuity: selectedUpgradesCategories[category.id]?.charges.applyGratuity ?? false,
            cardEntryOption: String(chargeDetails.cardEntryOption),
          }
        }

        upgradesFiltered.forEach(([upgradeId, count]) => {
          const upgrade = inventories.find(inventory => inventory.id === upgradeId)
          if (upgrade) {
            // category exists, we already created object above
            // eslint-disable-next-line
            selectedUpgradesCategories[category.id]!.upgrades[upgrade.id] = getUpgradesForm(count, upgrade)
          }
        })
      }
    })

    bundledUpgrades?.forEach(({ id, quantity_equal_type, quantity_num }) => {
      const upgrade = inventories.find(inventory => inventory.id === id)
      const category = upgrade ? categories.find(category => category.id === upgrade.categoryId) : undefined
      if (upgrade && category) {
        if (!selectedBundledUpgradesCategories[category.id]) {
          selectedBundledUpgradesCategories[category.id] = getCategoryForm(
            category,
            defaultGratuity,
            defaultServiceCharge,
            clientSelectGratuityCharge
          )

          // If client select gratuity fields arnt set yet, check if we can set them now
          if (!clientSelectGratuity.gratuityClientSelect && selectedBundledUpgradesCategories[category.id]?.charges.gratuityClientSelect) {
            clientSelectGratuity = {
              ...clientSelectGratuity,
              gratuityClientSelect: selectedBundledUpgradesCategories[category.id]?.charges.applyGratuity ?? false,
              requireGratuityCharge: selectedBundledUpgradesCategories[category.id]?.charges.requireGratuityCharge ?? false,
              applyGratuity: selectedBundledUpgradesCategories[category.id]?.charges.applyGratuity ?? false,
              cardEntryOption: String(chargeDetails.cardEntryOption),
            }
          }
        }
        const count = quantity_equal_type === 'SPECIFIC_NUMBER' ? quantity_num : quantity_num * partySize
        // category exists, we already created object above
        // eslint-disable-next-line
        selectedBundledUpgradesCategories[category.id]!.upgrades[upgrade.id] = getUpgradesForm(count, upgrade)
      }
    })

    const charges = {
      gratuity:
        chargeDetails.paylinkGratuityType === 'gratuity_percentage'
          ? Number(chargeDetails.gratuityCharge ?? '')
          : clientSelectGratuityCharge,
      gratuityClientSelect: chargeDetails.paylinkGratuityType !== 'gratuity_percentage',
      requireGratuityCharge: chargeDetails.paylinkGratuityType === 'require_client_select_charge',
      service: Number(chargeDetails.serviceCharge ?? ''),
      taxId: chargeDetails.taxGroupId ?? '',
      applyGratuity: !!chargeDetails.applyGratuityCharge,
      applyService: !!chargeDetails.applyServiceCharge,
      applyTax: !!chargeDetails.chargeApplyTax,
    }

    const isClientSelectGratuityDirty =
      clientSelectGratuityCharge !== null && oldValues?.clientSelectGratuity.gratuity !== clientSelectGratuityCharge
    const isChargesClientGratuityDirty = isClientSelectGratuityDirty && charges.gratuityClientSelect
    const isCategoriesBundledClientGratuityDirty =
      isClientSelectGratuityDirty && Object.values(selectedBundledUpgradesCategories).some(u => u.charges.gratuityClientSelect)

    return {
      amount: isPreselectedTime && oldValues ? oldValues.amount : Number(chargeDetails.chargeAmount ?? ''),
      charges: isPreselectedTime && oldValues && !isChargesClientGratuityDirty ? oldValues.charges : charges,
      categoriesBundled:
        isPreselectedTime && oldValues && !isCategoriesBundledClientGratuityDirty
          ? oldValues.categoriesBundled
          : selectedBundledUpgradesCategories,
      categories: selectedUpgradesCategories,
      clientSelectGratuity:
        isPreselectedTime && oldValues && !isClientSelectGratuityDirty ? oldValues.clientSelectGratuity : clientSelectGratuity,
    }
  }, [
    upgrades,
    clientSelectGratuityCharge,
    chargeDetails.clientSelectGratuity.gratuity,
    chargeDetails.clientSelectGratuity.gratuityClientSelect,
    chargeDetails.clientSelectGratuity.requireGratuityCharge,
    chargeDetails.clientSelectGratuity.applyGratuity,
    chargeDetails.paylinkGratuityType,
    chargeDetails.gratuityCharge,
    chargeDetails.cardEntryOption,
    chargeDetails.serviceCharge,
    chargeDetails.taxGroupId,
    chargeDetails.applyGratuityCharge,
    chargeDetails.applyServiceCharge,
    chargeDetails.chargeApplyTax,
    chargeDetails.chargeAmount,
    categoriesForm,
    bundledUpgrades,
    isPreselectedTime,
    oldValues,
    defaultGratuity,
    defaultServiceCharge,
    partySize,
  ])

function getCategoryForm(
  category: GuestFacingUpgradeCategory,
  defaultGratuity: number,
  defaultServiceCharge: number,
  clientSelectGratuityCharge: number | null
): CategoryForm {
  return {
    name: category.name,
    upgrades: {},
    charges: getChargesForm(category, defaultGratuity, defaultServiceCharge, clientSelectGratuityCharge),
  }
}

function getUpgradesForm(count: number, upgrade: GuestFacingUpgradeInventory): UpgradeForm {
  return {
    name: upgrade.name,
    count,
    amount: upgrade.price * count,
  }
}

function getChargesForm(
  category: GuestFacingUpgradeCategory,
  defaultGratuity: number,
  defaultServiceCharge: number,
  clientSelectGratuityCharge: number | null
): ChargesForm {
  let gratuity = null
  if (category.gratuityChargeType === 'CLIENT_GRATUITY') {
    gratuity = clientSelectGratuityCharge
  } else if (category.gratuityChargeType === 'DEFAULT_GRATUITY') {
    gratuity = defaultGratuity
  } else {
    gratuity = category.gratuityPercentage
  }

  return {
    gratuity: category.isChargingGratuity ? gratuity : 0,
    gratuityClientSelect: category.gratuityChargeType === 'CLIENT_GRATUITY',
    requireGratuityCharge: category.requireGratuityCharge,
    service:
      category.isChargingServiceCharge && !category.serviceChargePercentage ? defaultServiceCharge : category.serviceChargePercentage,
    taxId: category.taxGroupId,
    applyGratuity: category.isChargingGratuity,
    applyService: category.isChargingServiceCharge,
    applyTax: category.isChargingTax,
  }
}

export const getClientSelectGratuityValueFromUpgrades = (upgrades: UpsellData): number | null => {
  if (!upgrades) {
    return null
  }

  if (upgrades.selectedUpsells?.selected_categories) {
    for (const category of Object.values(upgrades?.selectedUpsells?.selected_categories)) {
      if (category.gratuity_percentage !== null && category.gratuity_charge_type === 'CLIENT_GRATUITY') {
        return category.gratuity_percentage
      }
    }
  }

  if (upgrades.automaticallyIncludedUpsells) {
    for (const upsell of upgrades?.automaticallyIncludedUpsells) {
      if (upsell.gratuity_client_select) {
        return upsell.gratuity
      }
    }
  }

  return null
}

export interface Upsell {
  id: string
  quantity_equal_type: string
  price: number
  quantity_num: number
  gratuity: number
  gratuity_client_select: boolean
}

interface SelectedCategory {
  gratuity_charge_type: string
  is_charging_gratuity: boolean
  gratuity_percentage: number | null
  is_charging_service_charge: boolean
  service_charge_percentage: number | null
  is_charging_tax: boolean
  tax_group_id: string | null
}

interface SelectedInventory {
  price: number
  quantity: number
}

interface SelectedUpsells {
  selected_categories?: {
    [key: string]: SelectedCategory
  }
  selected_inventories?: {
    [key: string]: SelectedInventory
  }
}

interface UpsellData {
  automaticallyIncludedUpsells?: Upsell[] // Can be undefined or an empty array
  selectedUpsells: SelectedUpsells
}

interface ClientSelectGratuity {
  gratuity: number | null
  gratuityClientSelect: boolean
  requireGratuityCharge: boolean
  applyGratuity: boolean
}
